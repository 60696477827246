<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Group</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="group.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Budget" :invalid-feedback="validationInvalidFeedback(errors.errors, 'budget')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'budget')" v-model="group.budget" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validation],
  computed: {
    ...mapGetters('groups', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  created() {
    this.fetch(this.$route.params.id)
  },
  data () {
    return {
      group: {
        id: null,
        name: null,
        budget: null,
      }
    }
  },
  methods: {

    ...mapActions('groups', ['fetch', 'save']),

    submit () {
      let data = {
        id: this.$route.params.id,
        name: this.group.name,
        budget: this.group.budget,
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.groups.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Group #${response.data.id}`,
              text: `The group details have been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },
  },
  watch: {
    single (newUser) {
      this.group = this.single
    }
  }
}
</script>

<style>

</style>
